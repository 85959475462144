import { CHAIN_TYPE_EVM, CHAIN_TYPE_TVM, CHAIN_TYPE_TON, CHAIN_TYPE_SOL } from "./ChainTypes";

export const SOLANA_MAINNET_CHAIN_ID = 50000
export const SOLANA_DEVNET_CHAIN_ID = 50001

export const DEF_MAINNET_CHAINS = [
  {
    chainId: 1,
    name: "Ethereum",
    img: "ethereum.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://eth.llamarpc.com",
      "https://ethereum.blockpi.network/v1/rpc/public",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://etherscan.io"],
  },
  {
    chainId: 137,
    name: "Polygon",
    img: "polygon.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.maticvigil.com/",
    ],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrls: ["https://polygonscan.com"],
  },
  {
    chainId: 1101,
    name: "Polygon zkEVM",
    img: "zkevm.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://rpc.ankr.com/polygon_zkevm"
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://zkevm.polygonscan.com"],
  },
  {
    chainId: 32520,
    name: "Birgert",
    img: "bitgert-24x24.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://rpc.icecreamswap.com"
    ],
    nativeCurrency: {
      name: "Brise",
      symbol: "Brise",
      decimals: 18,
    },
    blockExplorerUrls: ["https://brisescan.com"],
  },
  {
    chainId: 80001,
    name: "Polygon",
    img: "polygon.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.maticvigil.com/",
    ],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
  {
    chainId: 250,
    name: "Fantom",
    img: "fantom.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ["https://rpc.ftm.tools/", "https://rpcapi.fantom.network"],
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    blockExplorerUrls: ["https://ftmscan.com"],
  },
  {
    chainId: 4002,
    name: "Fantom",
    img: "fantom.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ["https://rpc.testnet.fantom.network", "https://fantom-testnet.publicnode.com"],
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    blockExplorerUrls: ["https://testnet.ftmscan.com"],
  },
  {
    chainId: 10,
    name: "Optimism",
    img: "optimism.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://1rpc.io/op",
      "https://endpoints.omniatech.io/v1/op/mainnet/public",
      "https://mainnet.optimism.io/"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
  },
  {
    chainId: 56,
    name: "BNB",
    img: "bsc.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/bsc/mainnet/public",
      "https://bsc-dataseed.binance.org/",
      "https://bsc-dataseed1.defibit.io/",
      "https://bsc-dataseed1.ninicoin.io/",
    ],
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },

    blockExplorerUrls: ["https://bscscan.com"],
  },
  {
    chainId: 97,
    name: "BNB",
    img: "bsc.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/bsc/testnet/public",
    ],
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },

    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
  {
    chainId: 43114,
    name: "Avalanche",
    img: "avalanche.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://avalanche.blockpi.network/v1/rpc/public",
      "https://avalanche-evm.publicnode.com",
      "https://1rpc.io/avax/c",
    ],
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },

    blockExplorerUrls: ["https://snowtrace.io"],
  },
  {
    chainId: 42161,
    name: "Arbitrum",
    img: "arbitrum.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/arbitrum/one/public",
      "https://arbitrum.blockpi.network/v1/rpc/public",
      "https://arb1.arbitrum.io/rpc",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },

    blockExplorerUrls: ["https://arbiscan.io"],
  },
  {
    chainId: 1313161554,
    name: "Aurora",
    img: "aurora.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://endpoints.omniatech.io/v1/aurora/mainnet/public",
      "https://mainnet.aurora.dev",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://arbiscan.io"],
  },
  {
    chainId: 42220,
    name: "Celo",
    img: "celo.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://forno.celo.org",
    ],
    nativeCurrency: {
      name: "CELO",
      symbol: "CELO",
      decimals: 18,
    },

    blockExplorerUrls: ["https://celoscan.io"],
  },
  {
    chainId: 288,
    name: "BOBA",
    img: "boba.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://mainnet.boba.network",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },

    blockExplorerUrls: ["https://bobascan.com"],
  },
  {
    chainId: 11155111,
    name: "Sepolia",
    img: "eth.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: [
      "https://eth-sepolia.g.alchemy.com/v2/demo",
    ],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },

    blockExplorerUrls: ["https://sepolia.etherscan.io"],
  },
  {
    chainId: 30001,
    name: "Venom",
    img: "venom.svg",

    chainType: CHAIN_TYPE_TVM,

    rpcUrls: ["https://jrpc.venom.foundation"],
    nativeCurrency: {
      name: "VENOM",
      symbol: "VENOM",
      decimals: 9,
    },

    blockExplorerUrls: ["https://venomscan.com"],
  },
  {
    chainId: 20001,
    name: "Ever",
    img: "ever.svg",
    chainType: CHAIN_TYPE_TVM,

    nativeCurrency: {
      name: "EVER",
      symbol: "EVER",
      decimals: 9,
    },

    blockExplorerUrls: ["https://everscan.io"],
  },
  {
    chainId: 5165,
    name: "Bahamut",
    img: "fastex.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ["https://rpc1.sahara.bahamutchain.com"],
    nativeCurrency: {
      name: "FTN",
      symbol: "FTN",
      decimals: 18,
    },

    blockExplorerUrls: ["https://ftnscan.com"],
  },

  {
    chainId: 8453,
    name: "Base",
    img: "base.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ["https://mainnet.base.org"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },

    blockExplorerUrls: ["https://basescan.org"],
  },
  {
    chainId: 59144,
    name: "Linea",
    img: "linea.svg",
    chainType: CHAIN_TYPE_EVM,
    rpcUrls: ["https://rpc.linea.build"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://lineascan.build"],
  },
  {
    chainId: 5000,
    name: "Mantle",
    img: "mantle.svg",
    chainType: CHAIN_TYPE_EVM,
    rpcUrls: ["https://rpc.mantle.xyz"],
    nativeCurrency: {
      name: "MNT",
      symbol: "MNT",
      decimals: 18,
    },
    blockExplorerUrls: ["https://explorer.mantle.xyz"],
  },
  {
    chainId: 100,
    name: "Gnosis",
    img: "gnosis.svg",
    chainType: CHAIN_TYPE_EVM,
    rpcUrls: ["https://rpc.gnosischain.com"],
    nativeCurrency: {
      name: "XDAI",
      symbol: "XDAI",
      decimals: 18,
    },
    blockExplorerUrls: ["https://gnosisscan.io"],
  },
  {
    chainId: 8192,
    name: "Torus",
    img: "TQF.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ["https://rpc.toruschain.com"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },

    blockExplorerUrls: ["https://toruscan.com"],
  },
  {
    chainId: 463,
    name: "Areon",
    img: "areon.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ["https://mainnet-rpc5.areon.network"],
    nativeCurrency: {
      name: "AREA",
      symbol: "AREA",
      decimals: 18,
    },

    blockExplorerUrls: ["https://areonscan.com"],
  },
  {
    chainId: 50,
    name: "XDC",
    img: "xdc.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ["https://rpc.xinfin.network"],
    nativeCurrency: {
      name: "XDC",
      symbol: "XDC",
      decimals: 18,
    },

    blockExplorerUrls: ["https://xdcscan.com"],
  },
  {
    chainId: 40000,
    name: "Ton",
    img: "ton.svg",
    chainType: CHAIN_TYPE_TON,

    rpcUrls: [],
    nativeCurrency: {
      name: "TON",
      symbol: "TON",
      decimals: 9,
    },
    blockExplorerUrls: ["https://testnet.tonviewer.com"],
  },
  {
    chainId: 40001,
    name: "Ton Testnet",
    img: "ton.svg",
    chainType: CHAIN_TYPE_TON,

    rpcUrls: [],
    nativeCurrency: {
      name: "TON",
      symbol: "TON",
      decimals: 9,
    },
    blockExplorerUrls: ["https://testnet.tonviewer.com"],
  },
  {
    chainId: SOLANA_MAINNET_CHAIN_ID,
    name: "Solana",
    img: "solana.svg",
    chainType: CHAIN_TYPE_SOL,

    rpcUrls: [],
    nativeCurrency: {
      name: "Solana",
      symbol: "Sol",
      decimals: 9,
    },
    blockExplorerUrls: ["https://explorer.solana.com"],
  },
  {
    chainId: SOLANA_DEVNET_CHAIN_ID,
    name: "Solana Devnet",
    img: "solana.svg",
    chainType: CHAIN_TYPE_SOL,

    rpcUrls: [],
    nativeCurrency: {
      name: "Solana",
      symbol: "Sol",
      decimals: 9,
    },
    blockExplorerUrls: ["https://explorer.solana.com"],
  },
  {
    chainId: 1006,
    name: "LemonChain",
    img: "lemonchain.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ['https://rpc.lemonchain.io'],
    nativeCurrency: {
      name: "LEMX",
      symbol: "LEMX",
      decimals: 18,
    },
    blockExplorerUrls: ["https://explorer.lemonchain.io"],
  },
  {
    chainId: 1005,
    name: "LemonChain Testnet",
    img: "lemonchain.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ['https://rpc.testnet.lemonchain.io'],
    nativeCurrency: {
      name: "tLEMX",
      symbol: "tLEMX",
      decimals: 18,
    },
    blockExplorerUrls: ["https://explorer.lemonchain.io"],
  },
  {
    chainId: 4158,
    name: "CrossFi Mainnet",
    img: "crossfi.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ['https://rpc.mainnet.ms'],
    nativeCurrency: {
      name: "XFI",
      symbol: "XFI",
      decimals: 18,
    },
    blockExplorerUrls: ["https://xfiscan.com"],
  },
  {
    chainId: 4157,
    name: "CrossFi Testnet",
    img: "crossfi.svg",
    chainType: CHAIN_TYPE_EVM,

    rpcUrls: ['https://rpc.testnet.ms'],
    nativeCurrency: {
      name: "XFI",
      symbol: "XFI",
      decimals: 18,
    },
    blockExplorerUrls: ["https://test.xfiscan.com"],
  },
];
